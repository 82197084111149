// src/Album.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Album = ({ token,setCoin }) => {
  const [album, setAlbum] = useState(null);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    async function fetchAlbum() {
      try {
        const response = await axios.get('https://api.spotify.com/v1/albums/5bfpRtBW7RNRdsm3tRyl3R', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log(response.data);
        setAlbum(response.data);
      } catch (error) {
        console.error('Error fetching album', error);
      }
    }

    fetchAlbum();
  }, [token]);

  const handleSaveAlbum = async () => {
    try {
      await axios.put(`https://api.spotify.com/v1/me/albums?ids=${album.id}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      alert('You have successfully saved the album to your library!');

      setCoin(1000);
      setSaved(true);
    } catch (error) {
      console.error('Error saving album', error);
    }
  };

  if (!album) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <div className='bg-white mx-auto mt-10 rounded-2xl text-center retro w-11/12 p-5'>
      {/* <img className='rounded mx-aut' src='https://upload.wikimedia.org/wikipedia/en/1/11/Dreamland_%28Glass_Animals%29.png'/> */}
      <h1 className='primary text-3xl'>{album.name}</h1>
      <p className='secondary text-3xl'>By {album.artists.map(artist => artist.name).join(', ')}</p>
      <button onClick={handleSaveAlbum} class="bg-[#FFD8C7] mb-10 mx-auto button-text block mt-10 retro-dropshadow py-4 retro text-xl w-10/12 text-white font-bold py-2 px-4 rounded-md">
  {saved? "Saved" : "Save our album"}
</button>
</div>
    </div>
  );
};

export default Album;

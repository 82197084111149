import React, { useState, useEffect } from 'react';
import Login from './Login';
import './App.css';
import Logo from './assets/logo.png';
import SpotifyLogin from './spotify/login';
import Playlist from './Album';
import Album from './Album';

function App() {
  const [token, setToken] = useState('');
  const [coin, setCoin] = useState(0);

  // useEffect(() => {


  //   if (token !== '') {
  //     setTimeout(() => {
  //       setAppIntro(false);
  //     }, 4000);


  //   }


  // }, [token]);

  useEffect(() => {
    async function getToken() {
      const loginToken = SpotifyLogin.getToken();
      if (!loginToken) {

      } else {
        setToken(loginToken);
      }
    }

    getToken();
  }, []);

  function handleLogout() {
    setToken(''); // Clear the access token
    window.location.href = 'https://www.spotify.com/logout/';
  }

  return (
    <>
    <div className='mx-auto ga-container'>
    <div className=' w-full bg-[#D1A8D2]'>
<img className='logo mx-auto block pt-10'  src={Logo}/>

    </div>
<div className=' ga-background'>



<div className='mx-auto retro'>
  <div className='w-full mt-5 md:mt-0 text-xl md:text-xl text-center'>
    <div className=''> Coins</div>
    <div className='text-5xl m-4'> {coin}</div>
    <div className=''> Please Insert Coin</div>
    <div className=' secondary'> 1st bonus at 1000 pts</div>
    <div className=' secondary'> 2nd bonus at 2000 pts</div>
  </div>
  </div>
{token === '' ? (<Login />) : (<Album setCoin={setCoin} token={token}/>)}
</div>


    {/* <img className='ga-background'  src={GABackground}/> */}
</div>

    </>
  );
}

export default App;


  

//       {token === '' ? (<></>) : (<>
//         <div >
//           {/* <WebPlayback initSongUI={initSongUI} resetPrompts={resetPrompts} token={token} /> */}
//         <Playlist token={token}/>

//         </div>


//       </>)}
import React from 'react';
import SpotifyLogin from './spotify/login';

function Login() {
  const handleLogin = () => {
    SpotifyLogin.logInWithSpotify();
  }

  return (
<button onClick={handleLogin} class="bg-[#FFD8C7] mx-auto button-text block mt-10 retro-dropshadow py-4 retro text-xl w-10/12 text-white font-bold py-2 px-4 rounded-md">
Login with Spotify
</button>
  );
}

export default Login;
